<template>
  <div id="hotGame">
    <div class="title">热门游戏</div>
    <div class="hotgame_content">
      <ul class="hotgame_list" v-for="item in hotGameListChunkone" @click="download(item.gw2,item)">
        <li class="hotgame_li">
          <div class="list_img">
            <img :src="item.img" alt />
          </div>
          <div class="list_content">
            <div class="title_box">{{ item.head }}</div>
            <div class="descibe_box">
              <div class="version">版本：{{ item.version }}</div>
              <div class="newOpen">上线时间:{{ item.time }}</div>
              <div class="newOpen">最新开服时间：{{ item.newOpen }}</div>
            </div>
            <div class="gw">官网：{{ item.gw }}</div>
          </div>
        </li>
      </ul>
      <!--  -->
    </div>
    <div class="hotgame_content">
      <ul class="hotgame_list" v-for="twoItem in hotGameListChunktwo">
        <li class="hotgame_li">
          <div class="list_img">
            <img :src="twoItem.img" alt />
          </div>
          <div class="list_content">
            <div class="title_box">{{ twoItem.head }}</div>
            <div class="descibe_box">
              <div class="version">版本：{{ twoItem.version }}</div>
              <div class="newOpen">上线时间:{{ twoItem.time }}</div>
              <div class="newOpen">最新开服时间：{{ twoItem.newOpen }}</div>
            </div>
            <div class="gw">官网：{{ twoItem.gw }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { downloadA } from "@/utils/download";
export default {
  data() {
    return {
      hotGameList: [
        {
          isShow:"https://xqn.3975qn.com/",
          img: require("@/assets/image/wap/hotGame/9.jpg"),
          head: "《新千年-正版授权》",
          version: "绿色版",
          time: "",
          newOpen: "限号内侧中",
          gw: "",
          gw2: "",
        },
      {
          img: require("@/assets/image/wap/hotGame/jsmj.png"),
          head: "《绝世秘籍》",
          version: "一层版本",
          time: "2024.8.31",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/mhqn.jpg"),
          head: "《梦幻千年》",
          version: "二层版本",
          time: "2024.7.22",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/bxqn.png"),
          head: "《冰雪千年》",
          version: "独家版本",
          time: "2024.6.29",
          newOpen: "2024.6.29",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/jxq.png"),
          head: "《剑侠情》",
          version: "一层版本",
          time: "2024.7.18",
          newOpen: "2024.7.26",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/qxz.png"),
          head: "《群侠传》",
          version: "一层版本",
          time: "2024.8.7",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/jyqn.png"),
          head: "《九妖千年》",
          version: "高倍怀旧版本",
          time: "",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/ltzj.png"),
          head: "《戮天之剑》",
          version: "二层版本",
          time: "2024.5.10",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/rx.png"),
          head: "《热血Online》",
          version: "一层版本",
          time: "2024.6.7",
          newOpen: "2024.6.12",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/jjgc.png"),
          head: "《精绝古城》",
          version: "绿色版",
          time: "2024.7.16",
          newOpen: "2024.7.20",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/wdqn.png"),
          head: "《武动千年》",
          version: "二层版本",
          time: "2024.7.10",
          newOpen: "2024.7.13",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/qnwc.png"),
          head: "《千年王朝》",
          version: "一层版本",
          time: "2024.7.6",
          newOpen: "",
          gw: "",
          gw2: "",
        },

        {
          img: require("@/assets/image/wap/hotGame/yyjh.png"),
          head: "《烟雨江湖》",
          version: "一层版本",
          time: "2024.7.20",
          newOpen: "",
          gw: "",
          gw2: "",
        },

        // {
        //   // img: '/assets/image/wap/hotGame/1.jpg',
        //   img: require("@/assets/image/wap/hotGame/1.jpg"),
        //   head: "《干年盛世绿色版》",
        //   version: "二层版本",
        //   newOpen: "2023.10.13",
        //   time: "2023.7.22",
        //   gw: "",
        //   gw2: "",
        // },

        {
          img: require("@/assets/image/wap/hotGame/3.jpg"),
          head: "《灵武世界》",
          version: "一层版本",
          newOpen: "2023.9.28",
          time: "2023.9.28",
          gw: "",
          gw2: "",
        },

        // {
        //   img: require("@/assets/image/wap/hotGame/5.jpg"),
        //   head: "《创世千年》",
        //   version: "二层版本",
        //   time:"2023.10.28",
        //   newOpen: "2023.11.2",
        //   gw: "",
        //   gw2: "",
        // },
        // {
        //   img: require("@/assets/image/wap/hotGame/8.jpg"),
        //   head: "《千年世界》",
        //   version: "二层版本",
        //   time: "2023.10.22",
        //   newOpen: "2023.10.27",
        //   gw: "",
        //   gw2: "",
        // },
        // {
        //   img: require("@/assets/image/wap/hotGame/6.jpg"),
        //   head: "《千年盛世之龙麟决》",
        //   version: "二层版本",
        //   time: "2023.12.8",
        //   newOpen: "2023.12.10",
        //   gw: "",
        //   gw2: "",
        // },
        {
          img: require("@/assets/image/wap/hotGame/7.jpg"),
          head: "《千年盛世之极纯千年》",
          version: "一层版本",
          time: "2023.12.1",
          newOpen: "2023.12.12",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/fs.jpg"),
          head: "《封神千年》",
          version: "一层版本",
          time: "2023.11.2",
          newOpen: "2023.11.6",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/jj.jpg"),
          head: "《九剑魔龙传》",
          version: "二层版本",
          time: "2023.10.27",
          newOpen: "2023.11.3",
          gw: "",
          gw2: "",
        },
        // {
        //   img: require("@/assets/image/wap/hotGame/zx.jpg"),
        //   head: "《再续千缘》",
        //   version: "一层版本",
        //   time:'2023.11.5',
        //   newOpen: "",
        //   gw: "",
        //   gw2: "",
        // },
        {
          img: require("@/assets/image/wap/hotGame/fg.jpg"),
          head: "《复古千年》",
          version: "二层版本",
          time: "2023.11.9",
          newOpen: "2023.11.17",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/da.jpg"),
          head: "《风云再起》",
          version: "一层版本",
          time: "2023.11.24",
          newOpen: "",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/qngl.jpg"),
          head: "《千年归来》",
          version: "武功全自创版",
          time: "2023.11.29",
          newOpen: "2023.12.29",
          gw: "",
          gw2: "",
        },
        // {
        //   img: require("@/assets/image/wap/hotGame/syqn.jpg"),
        //   head: "《神游千年》",
        //   version: "二层版本",
        //   time: "",
        //   newOpen: "2023.12.22",
        //   gw: "",
        //   gw2: "",
        // },
        // {
        //   img: require("@/assets/image/wap/hotGame/syqn.jpg"),
        //   head: "《太极之章》",
        //   version: "二层版本",
        //   time:'2023.12.2',
        //   newOpen: "2023.12.10",
        //   gw: "",
        //   gw2: "",
        // },
        // {
        //   img: require("@/assets/image/wap/hotGame/zzjz.jpg"),
        //   head: "《征战九州版》",
        //   version: "二层版本",
        //   time: "2023.12.3",
        //   newOpen: "2023.12.15",
        //   gw: "",
        //   gw2: "",
        // },
        {
          img: require("@/assets/image/wap/hotGame/smjs.png"),
          head: "《水墨江山》",
          version: "一层版本",
          time: "2024.1.21",
          newOpen: "2024.1.27",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/jyrg.png"),
          head: "《剑雨如歌》",
          version: "武功全自创版",
          time: "",
          newOpen: "2024.1.30",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/xzd.jpg"),
          head: "《侠众道》",
          version: "二层版本",
          time: "2023.11.17",
          newOpen: "2024.2.1",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/szsd.png"),
          head: "《神州三端》",
          version: "一层版本",
          time: "",
          newOpen: "2024.2.2",
          gw: "",
          gw2: "",
        },

        {
          img: require("@/assets/image/wap/hotGame/lhcfjz.png"),
          head: "《离火重返激战》",
          version: "二层版本",
          time: "2024.4.22",
          newOpen: "2024.4.27",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/xkxqn.png"),
          head: "《侠客行千年》",
          version: "三层版本",
          time: "",
          newOpen: "2024.3.14",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/dxts.png"),
          head: "《大侠坛说》",
          version: "一层版本",
          time: "2024.4.1",
          newOpen: "2024.4.6",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/jyss.png"),
          head: "《九妖盛世版》",
          version: "二层版本",
          time: "2024.4.20",
          newOpen: "2024.4.30",
          gw: "",
          gw2: "",
        },

        {
          img: require("@/assets/image/wap/hotGame/zzbj.png"),
          head: "《诸子百家之千年武侠》",
          version: "二层版本",
          time: "2024.5.6",
          newOpen: "2024.5.12",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/wjjh.png"),
          head: "《问剑江湖》",
          version: "一层版本",
          time: "2024.4.26",
          newOpen: "2024.5.1",
          gw: "",
          gw2: "",
        },
        {
          img: require("@/assets/image/wap/hotGame/xss.png"),
          head: "《新盛世》",
          version: "二层版本",
          time: "",
          newOpen: "",
          gw: "",
          gw2: "",
        },
      ],
    };
  },

  mounted() {},
  computed: {
    hotGameListChunkone() {
      const oneIndexArray = [];
      const content = this.hotGameList;
      for (let i = 0; i < content.length; i++) {
        if (i % 2 === 0) {
          oneIndexArray.push(content[i]);
        }
      }
      // console.log(oneIndexArray, 1);
      return oneIndexArray;
    },
    hotGameListChunktwo() {
      const twoIndexArray = [];
      const content = this.hotGameList;
      for (let i = 0; i < content.length; i++) {
        if (i % 2 != 0) {
          twoIndexArray.push(content[i]);
        }
      }
      // console.log(twoIndexArray, 2);
      return twoIndexArray;
    },
  },
  created() {},
  methods: {
    download(url,item) {
      let a = url
      if(item.isShow){
        a = item.isShow
      }
      if (!a) {
        return;
      }
      downloadA(a)
    },
  },
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;

  color: #949494;
}

#hotGame {
  width: 100%;
  height: 100%;
  background: url(../../assets/image/wap/bg2/2.png) no-repeat;
  background-size: cover;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  .title {
    font-weight: bold;
    font-size: 0.533333rem /* 20/37.5 */;
    color: #f8f3e9;
    padding-top: 0.533333rem /* 20/37.5 */;
    padding-bottom: 0.533333rem /* 20/37.5 */;
  }

  .hotgame_content {
    width: 97%;
    height: 7.24rem /* 534/37.5 */;
    display: flex;
    overflow-x: auto;
    margin-right: 0.353333rem /* 17/37.5 */;
    // margin-bottom: 0.8rem /* 30/37.5 */;
    box-sizing: border-box;
    .hotgame_list {
      flex-shrink: 0;
      .hotgame_li {
        flex-basis: calc(25% - 10px);
        /* 4个元素一行，减去间距的宽度 */
        height: 6.72rem /* 252/37.5 */;
        border-radius: 0.266667rem /* 10/37.5 */;
        margin-left: 0.3rem;
        box-sizing: border-box;
        .list_img {
          width: 5.413333rem /* 203/37.5 */;
          height: auto;
          // height: 4.026667rem /* 151/37.5 */;
          img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 0.266667rem 0.266667rem 0 0 /* 10/37.5 */;
          }
        }

        .list_content {
          width: 5.413333rem /* 203/37.5 */;
          // height:3.5rem;
          box-sizing: border-box;
          line-height: 0.53333rem;
          background: #f6f5f4;
          text-align: left;
          //   margin-right: 0.266667rem /* 10/37.5 */;
          box-sizing: border-box;
          padding-top: 0.12rem /* 10/37.5 */;
          padding-bottom: 0.12rem /* 10/37.5 */;
          border-radius: 0 0 0.266667rem 0.266667rem /* 10/37.5 */;
          .title_box {
            color: #393333;
            font-size: 0.326667rem /* 16/37.5 */;
            font-weight: bold;
            // padding-bottom: .266667rem /* 10/37.5 */;
          }

          .descibe_box {
            font-size: 0.32rem /* 12/37.5 */;
            color: #949494;
            padding-left: 0.266667rem /* 10/37.5 */;
          }

          .gw {
            font-size: 0.32rem /* 12/37.5 */;
            color: #949494;
            padding-left: 0.266667rem;
            font-size: 0.26rem;
            /* 10/37.5 */
          }
        }
      }
    }
  }
}
</style>
