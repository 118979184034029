<template>
  <div id="newCenter " class="original">
    <div class="newCenter_box max-width">
      <div class="new_title">资讯中心</div>
      <div class="new_center_box">
        <div class="new_menu">
          <div class="new_menu_ul">
            <div v-for="(item, index) in menuList" :key="index" @click="changeNav(index)" class="menu_item"
              :class="indexs == index ? 'active' : ''">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="swiper newCenter_swiper">
          <div class="swiper-wrapper">
            <!-- 对应导航的小模块 -->
            <div class="new_content swiper swiper-slide stop-swiping" :class="`game_swiper_public${index}`"
              v-for="(item, index) in menuList" :key="index">
              <!-- 对应导航模块下面的list -->
              <div class="swiper-wrapper">
                <div class="item_content_box swiper-slide" v-for="(each, eachIndex) in item.list" :key="eachIndex">
                  <!-- 对应list下面的每一块内容 -->
                  <div @click="wapSingel(present.wz)" class="hand" v-for="(present, presentIndex) in each">
                    <div class="item_title">{{ present.head }}</div>
                    <div class="item_content">
                      <div class="item_lef">
                        <img :src="present.url" alt="" />
                      </div>
                      <div class="item_rig">
                        <div class="item_info">{{ present.info }}</div>

                        <div class="item_foot">
                          <div class="item_time">{{ present.time }}</div>
                          <div class="item_btn">
                            <div class="btn_tex">查看更多</div>
                            <div class="btn_img">
                              <img src="../../assets/image/icon/27.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="empty_img" v-if="!item.list || item.list.length === 0">
                <div class="empty_img">
                  <img src="../../assets/image/pc/bg/0.png" alt="" />
                </div>
                <div class="empty_tex">暂无内容</div>
              </div>

              <div class="swiper-pagination" v-if="item.list.length > 1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indexs: 0,
      isShow: false,
      menuList: [
        {
          title: "全部资讯",
          show: true,
          list: [
            [
              {
                show: false,
                head: "《千年游戏盒子》PC端新版本2024年10月17日正式上线！",
                url: require("@/assets/image/wap/banner/1012.jpg"),
                info: "10月17日-20日来千年盒子领取新版本大礼包！游戏礼包、扑克牌、云挂机15日VIP卡免费大放送，诚邀广大千年侠客下载体验！",
                time: "  2024-10-12",
                wz: "https://mp.weixin.qq.com/s/6VTCfXCFU-5o1ojbhHHm7Q",
              },
              {
                show: false,
                head: "国庆嗨翻天，好礼多重奏！",
                url: require("@/assets/image/wap/banner/gq.jpg"),
                info: "10月1日-10月7日，来《千年游戏盒子》，千年游戏礼包、定制扑克送不停，更有9.9元月卡限量抢购！",
                time: "  2024-9-23",
                wz: "https://mp.weixin.qq.com/s/lEPv2hxGm0ec7b-EDY98ZQ",
              },

            ],
            [
              {
                show: false,
                head: "金秋九月，《千年游戏盒子》签到积分翻倍，游戏礼包大换新，好礼送不停！",
                url: require("@/assets/image/wap/banner/jqjy.jpg"),
                info: "8月30日-9月3日，《千年游戏盒子》签到积分翻倍！8月30日起，千年盒子游戏礼包大换新，更有千年云挂机第N波福利来袭，快来领取兑换吧~",
                time: "  2024-8-27",
                wz: "https://mp.weixin.qq.com/s/DIQ9AfH0505vfgO0qBQtOg",
              },
              {
                show: false,
                head: "一篇文章带您了解千年游戏史！",
                url: require("@/assets/image/wap/banner/256-144.jpg"),
                info: "回味经典千年，致敬千年情怀！带您一键了解千年发展史，前往千年盒子参与活动更有云挂机好礼相赠！",
                time: "  2024-8-20",
                wz: "https://mp.weixin.qq.com/s/abzYupDefKAkLi9oBlRZtw",
              },


            ],
            [
              {
                show: false,
                head: "《千年游戏盒子》福利加码，千年定制扑克牌礼盒免费大放送！",
                url: require("@/assets/image/wap/banner/new8.jpg"),
                info: "8月20日-31日，《千年游戏盒子》960份千年定制版扑克牌免费兑换，更有66份惊喜随机放送！积分兑换，趣味互动，好礼送不停~",
                time: "  2024-8-20",
                wz: "https://mp.weixin.qq.com/s/N0V3L5tbZEo5MpCWE_DJ1w",
              },
              {
                show: false,
                head: "千年盒子PC(电脑）端下载教程",
                url: require("@/assets/image/wap/banner/new-1.jpg"),
                info: "千年盒子PC（电脑）端将于2024年8月13日开启限量内测，届时将在官网开放PC（电脑）端下载入口，诚邀广大千年侠客体验！",
                time: "  2024-8-10",
                wz: "https://mp.weixin.qq.com/s/SfqiCnSMZ8mvNaXA3P6_Mw",
              },


            ],

            [
              {
                show: false,
                head: "《千年游戏盒子》携手<千年定制版香薰>伴您过七夕！",
                url: require("@/assets/image/wap/banner/zxqx.jpg"),
                info: "爱在七夕，赠你甜蜜！为感谢侠客们对《千年游戏盒子》的支持和厚爱，我们精心准备了520套 “千年定制版车载香薰” ，数量有限，先到先得！",
                time: "  2024-8-3",
                wz: "https://mp.weixin.qq.com/s/836nCSF0PfGnW8YiQ4Jj2w",
              },
              {
                show: false,
                head: "《千年游戏盒子》庆祝祖国八一建军节特别活动",
                url: require("@/assets/image/wap/banner/zxjj.jpg"),
                info: "值此建军佳节，《千年游戏盒子》特推出签到积分翻倍、云挂机15日VIP卡免费送活动，致敬伟大中国人民解放军！",
                time: "  2024-8-3",
                wz: "https://mp.weixin.qq.com/s/iCvaeVBwQk5Y_6DahnL8fw",
              },


            ],
            [
              {
                show: false,
                head: "千年盒子官方福利：免费赠送3天云挂机VIP卡",
                url: require("@/assets/image/wap/banner/hz.png"),
                info: "千年游戏盒子PC（电脑）端即将开启对外内测！官方特准备了10000张云挂机3天VIP卡免费赠送给各位玩家,'助'您千年之路事半功倍！数量有限，先到先得，送完即止！",
                time: "  2024-7-16",
                wz: "https://mp.weixin.qq.com/s/aVSqvXaqN2sM_tgtNirLLQ",
              },
              {
                show: false,
                head: "千年游戏盒子PC(电脑）端与您相约8月！",
                url: require("@/assets/image/wap/banner/pchz.png"),
                info: "千年盒子PC（电脑）端将于2024年8月初开启限量内测，8月初我们将在官网开放PC（电脑）端下载入口，诚邀广大千年侠客体验！",
                time: "  2024-7-25",
                wz: "https://mp.weixin.qq.com/s/3f7J87OPvCtbykbR5izsNQ",
              },


            ],
            [
              {
                show: false,
                head: "官宣：《千年》网络游戏独家代理公告",
                url: require("@/assets/image/pc/bg/2.png"),
                info: "我们很荣幸的告知您（江苏三九互娱网络科技有限公司）已取得《千年》网络游戏在中国大陆地区独占性著作权相关权利，现诚邀各位合作伙伴加入我们，我们相信只有共同努力才能打造好千年游戏IP，为广大玩家提供更优质的游戏体验我们诚邀各位...",
                time: " 2023-10-23",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484840&idx=1&sn=197a0fc045c866b987b15428d7102acf&chksm=cf7d76abf80affbd735dce45cfb1cc8ae223e69160a13a54a3cb75c51e736cb9a014dd340fe8&token=1515630829&lang=zh_CN#rd",
              },
              {
                show: false,
                head: "致侠客们的一封信：10月13日19:00 特殊新服《中国大陆独代区》",
                url: require("@/assets/image/pc/bg/3.png"),
                info: "《中国大陆独代区》 对我们，对《千年》IP都意义非凡，在产品推广，产品运营，内容策划上我们都会尽最大努力做好，不失绿色服宗旨，给所有喜爱千年的侠客们一个真正的情怀之所10月13日19：00：新区《中国大陆独代区》扬帆起航。即将更新....",
                time: " 2023-10-05",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484817&idx=1&sn=531fd768d9500c4f89d75799b4c52f6e&chksm=cf7d7692f80aff842fbc3ee6d926b8c34ad79b9fc1db218092405cd58506c248c7758810fb90&token=1515630829&lang=zh_CN#rd",
              },

            ],
            [
              {
                show: false,
                head: "感恩回馈“千年定制版车载香薰”免费送咯",
                url: require("@/assets/image/pc/bg/4.jpg"),
                info: "千年盛世绿色版 自7月22日公测以来受到了广大千年侠客的喜爱和支持，非常感谢侠客们对我们的支持和厚爱，我们为此精心准备了600套 “千年定制版车载香薰” 回馈各位侠客,聊表心意… … ",
                time: "  2023-08-27",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484656&idx=1&sn=b43f0d1738b9f4808062cf3b47fd94eb&chksm=cf7d77f3f80afee51f25eebe4665aa22b73167f2832a8619dc8e7262405111105acf9c254a28&token=1515630829&lang=zh_CN#rd",
              },
              {
                show: false,
                head: "喜讯：千年游戏盒子上线咯",
                url: require("@/assets/image/pc/bg/4.png"),
                info: "回首那些年，经典的千年游戏曾经陪伴我们度过了无数个青春岁月。它不仅仅是游戏，更是我们童年的记忆，是我们与好友一起嬉戏的见证。然而，时间的流转让这些游戏逐渐随风而去，我们只能默默怀念，怀念那段美好而难忘的时光...",
                time: " 2023-11-20",
                wz: "https://mp.weixin.qq.com/s?__biz=MzkwNTUzNjI3OA==&mid=2247483889&idx=1&sn=a32733612696bb324ad7d9ce5ba7b26e&chksm=c0f77788f780fe9ed577648185bab9825f0dbfcd54d8a639c049a9f8dc9f85dd984ed4d1fbcd#rd",
              },
            ],
            [
              {
                show: false,
                head: "千年游戏盒子福利一览",
                url: require("@/assets/image/pc/bg/5.png"),
                info: "千年游戏盒子作为一站式游戏平台，集成了多个版本的千年游戏，让您无需在不同平台之间切换，只需在千年游戏盒子中选择游戏启动即可,千年游戏盒子注重玩家的账号和个人信息安全，采取了多种安全措施，防止恶意攻击和信息泄露....",
                time: "2023-11-13 ",
                wz: "https://mp.weixin.qq.com/s?__biz=MzkwNTUzNjI3OA==&mid=2247483857&idx=1&sn=f4a9a109e58430498cebfecd0d99c680&chksm=c0f777a8f780febeff7e4c2b1a2fa2a9126df15a57d69f6c1bb59af812ca4904db60e27b5ceb#rd",
              },
              {
                show: false,
                head: "中秋赏月“捉迷藏”、“猜灯谜”，千年定制扑克牌，云挂机15日VIP卡大放送！",
                url: require("@/assets/image/wap/banner/256-144o.jpg"),
                info: "中秋习俗大赏，9月16日-9月18日，每天12点，来《千年游戏盒子》，1积分抢千年定制扑克牌，云挂机15日VIP卡，数量有限，先到先得！",
                time: "  2024-9-16",
                wz: "https://mp.weixin.qq.com/s/90jEvtl5JpBA1hiKfxMyiA",
              },
            ]
          ],
        },
        {
          title: "公告",
          show: false,
          list: [
            [
              {
                show: false,
                head: "《千年游戏盒子》PC端新版本2024年10月17日正式上线！",
                url: require("@/assets/image/wap/banner/1012.jpg"),
                info: "10月17日-20日来千年盒子领取新版本大礼包！游戏礼包、扑克牌、云挂机15日VIP卡免费大放送，诚邀广大千年侠客下载体验！",
                time: "  2024-10-12",
                wz: "https://mp.weixin.qq.com/s/6VTCfXCFU-5o1ojbhHHm7Q",
              },
              {
                show: false,
                head: "国庆嗨翻天，好礼多重奏！",
                url: require("@/assets/image/wap/banner/gq.jpg"),
                info: "10月1日-10月7日，来《千年游戏盒子》，千年游戏礼包、定制扑克送不停，更有9.9元月卡限量抢购！",
                time: "  2024-9-23",
                wz: "https://mp.weixin.qq.com/s/lEPv2hxGm0ec7b-EDY98ZQ",
              },

            ],
            [
              {
                show: false,
                head: "金秋九月，《千年游戏盒子》签到积分翻倍，游戏礼包大换新，好礼送不停！",
                url: require("@/assets/image/wap/banner/jqjy.jpg"),
                info: "8月30日-9月3日，《千年游戏盒子》签到积分翻倍！8月30日起，千年盒子游戏礼包大换新，更有千年云挂机第N波福利来袭，快来领取兑换吧~",
                time: "  2024-8-27",
                wz: "https://mp.weixin.qq.com/s/DIQ9AfH0505vfgO0qBQtOg",
              },
              {
                show: false,
                head: "一篇文章带您了解千年游戏史！",
                url: require("@/assets/image/wap/banner/256-144.jpg"),
                info: "回味经典千年，致敬千年情怀！带您一键了解千年发展史，前往千年盒子参与活动更有云挂机好礼相赠！",
                time: "  2024-8-20",
                wz: "https://mp.weixin.qq.com/s/abzYupDefKAkLi9oBlRZtw",
              },


            ],
            [
              {
                show: false,
                head: "《千年游戏盒子》福利加码，千年定制扑克牌礼盒免费大放送！",
                url: require("@/assets/image/wap/banner/new8.jpg"),
                info: "8月20日-31日，《千年游戏盒子》960份千年定制版扑克牌免费兑换，更有66份惊喜随机放送！积分兑换，趣味互动，好礼送不停~",
                time: "  2024-8-20",
                wz: "https://mp.weixin.qq.com/s/N0V3L5tbZEo5MpCWE_DJ1w",
              },
              {
                show: false,
                head: "千年盒子PC(电脑）端下载教程",
                url: require("@/assets/image/wap/banner/new-1.jpg"),
                info: "千年盒子PC（电脑）端将于2024年8月13日开启限量内测，届时将在官网开放PC（电脑）端下载入口，诚邀广大千年侠客体验！",
                time: "  2024-8-10",
                wz: "https://mp.weixin.qq.com/s/SfqiCnSMZ8mvNaXA3P6_Mw",
              },


            ],

            [
              {
                show: false,
                head: "《千年游戏盒子》携手<千年定制版香薰>伴您过七夕！",
                url: require("@/assets/image/wap/banner/zxqx.jpg"),
                info: "爱在七夕，赠你甜蜜！为感谢侠客们对《千年游戏盒子》的支持和厚爱，我们精心准备了520套 “千年定制版车载香薰” ，数量有限，先到先得！",
                time: "  2024-8-3",
                wz: "https://mp.weixin.qq.com/s/836nCSF0PfGnW8YiQ4Jj2w",
              },
              {
                show: false,
                head: "《千年游戏盒子》庆祝祖国八一建军节特别活动",
                url: require("@/assets/image/wap/banner/zxjj.jpg"),
                info: "值此建军佳节，《千年游戏盒子》特推出签到积分翻倍、云挂机15日VIP卡免费送活动，致敬伟大中国人民解放军！",
                time: "  2024-8-3",
                wz: "https://mp.weixin.qq.com/s/iCvaeVBwQk5Y_6DahnL8fw",
              },


            ],
            [
              {
                show: false,
                head: "千年盒子官方福利：免费赠送3天云挂机VIP卡",
                url: require("@/assets/image/wap/banner/hz.png"),
                info: "千年游戏盒子PC（电脑）端即将开启对外内测！官方特准备了10000张云挂机3天VIP卡免费赠送给各位玩家,'助'您千年之路事半功倍！数量有限，先到先得，送完即止！",
                time: "  2024-7-16",
                wz: "https://mp.weixin.qq.com/s/aVSqvXaqN2sM_tgtNirLLQ",
              },
              {
                show: false,
                head: "千年游戏盒子PC(电脑）端与您相约8月！",
                url: require("@/assets/image/wap/banner/pchz.png"),
                info: "千年盒子PC（电脑）端将于2024年8月初开启限量内测，8月初我们将在官网开放PC（电脑）端下载入口，诚邀广大千年侠客体验！",
                time: "  2024-7-25",
                wz: "https://mp.weixin.qq.com/s/3f7J87OPvCtbykbR5izsNQ",
              },


            ],
            [
              {
                show: false,
                head: "官宣：《千年》网络游戏独家代理公告",
                url: require("@/assets/image/pc/bg/2.png"),
                info: "我们很荣幸的告知您（江苏三九互娱网络科技有限公司）已取得《千年》网络游戏在中国大陆地区独占性著作权相关权利，现诚邀各位合作伙伴加入我们，我们相信只有共同努力才能打造好千年游戏IP，为广大玩家提供更优质的游戏体验我们诚邀各位...",
                time: " 2023-10-23",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484840&idx=1&sn=197a0fc045c866b987b15428d7102acf&chksm=cf7d76abf80affbd735dce45cfb1cc8ae223e69160a13a54a3cb75c51e736cb9a014dd340fe8&token=1515630829&lang=zh_CN#rd",
              },
              {
                show: false,
                head: "致侠客们的一封信：10月13日19:00 特殊新服《中国大陆独代区》",
                url: require("@/assets/image/pc/bg/3.png"),
                info: "《中国大陆独代区》 对我们，对《千年》IP都意义非凡，在产品推广，产品运营，内容策划上我们都会尽最大努力做好，不失绿色服宗旨，给所有喜爱千年的侠客们一个真正的情怀之所10月13日19：00：新区《中国大陆独代区》扬帆起航。即将更新....",
                time: " 2023-10-05",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484817&idx=1&sn=531fd768d9500c4f89d75799b4c52f6e&chksm=cf7d7692f80aff842fbc3ee6d926b8c34ad79b9fc1db218092405cd58506c248c7758810fb90&token=1515630829&lang=zh_CN#rd",
              },

            ],
            [
              {
                show: false,
                head: "感恩回馈“千年定制版车载香薰”免费送咯",
                url: require("@/assets/image/pc/bg/4.jpg"),
                info: "千年盛世绿色版 自7月22日公测以来受到了广大千年侠客的喜爱和支持，非常感谢侠客们对我们的支持和厚爱，我们为此精心准备了600套 “千年定制版车载香薰” 回馈各位侠客,聊表心意… … ",
                time: "  2023-08-27",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484656&idx=1&sn=b43f0d1738b9f4808062cf3b47fd94eb&chksm=cf7d77f3f80afee51f25eebe4665aa22b73167f2832a8619dc8e7262405111105acf9c254a28&token=1515630829&lang=zh_CN#rd",
              },
              {
                show: false,
                head: "喜讯：千年游戏盒子上线咯",
                url: require("@/assets/image/pc/bg/4.png"),
                info: "回首那些年，经典的千年游戏曾经陪伴我们度过了无数个青春岁月。它不仅仅是游戏，更是我们童年的记忆，是我们与好友一起嬉戏的见证。然而，时间的流转让这些游戏逐渐随风而去，我们只能默默怀念，怀念那段美好而难忘的时光...",
                time: " 2023-11-20",
                wz: "https://mp.weixin.qq.com/s?__biz=MzkwNTUzNjI3OA==&mid=2247483889&idx=1&sn=a32733612696bb324ad7d9ce5ba7b26e&chksm=c0f77788f780fe9ed577648185bab9825f0dbfcd54d8a639c049a9f8dc9f85dd984ed4d1fbcd#rd",
              },
            ],
            [
              {
                show: false,
                head: "千年游戏盒子福利一览",
                url: require("@/assets/image/pc/bg/5.png"),
                info: "千年游戏盒子作为一站式游戏平台，集成了多个版本的千年游戏，让您无需在不同平台之间切换，只需在千年游戏盒子中选择游戏启动即可,千年游戏盒子注重玩家的账号和个人信息安全，采取了多种安全措施，防止恶意攻击和信息泄露....",
                time: "2023-11-13 ",
                wz: "https://mp.weixin.qq.com/s?__biz=MzkwNTUzNjI3OA==&mid=2247483857&idx=1&sn=f4a9a109e58430498cebfecd0d99c680&chksm=c0f777a8f780febeff7e4c2b1a2fa2a9126df15a57d69f6c1bb59af812ca4904db60e27b5ceb#rd",
              },
              {
                show: false,
                head: "中秋赏月“捉迷藏”、“猜灯谜”，千年定制扑克牌，云挂机15日VIP卡大放送！",
                url: require("@/assets/image/wap/banner/256-144o.jpg"),
                info: "中秋习俗大赏，9月16日-9月18日，每天12点，来《千年游戏盒子》，1积分抢千年定制扑克牌，云挂机15日VIP卡，数量有限，先到先得！",
                time: "  2024-9-16",
                wz: "https://mp.weixin.qq.com/s/90jEvtl5JpBA1hiKfxMyiA",
              },
            ]
          ],
        },
        {
          title: "攻略",
          show: false,
          list: [],
        },
        {
          title: "活动",
          show: false,
          list: [
            [
              {
                show: false,
                head: "《千年游戏盒子》PC端新版本2024年10月17日正式上线！",
                url: require("@/assets/image/wap/banner/1012.jpg"),
                info: "10月17日-20日来千年盒子领取新版本大礼包！游戏礼包、扑克牌、云挂机15日VIP卡免费大放送，诚邀广大千年侠客下载体验！",
                time: "  2024-10-12",
                wz: "https://mp.weixin.qq.com/s/6VTCfXCFU-5o1ojbhHHm7Q",
              },
              {
                show: false,
                head: "国庆嗨翻天，好礼多重奏！",
                url: require("@/assets/image/wap/banner/gq.jpg"),
                info: "10月1日-10月7日，来《千年游戏盒子》，千年游戏礼包、定制扑克送不停，更有9.9元月卡限量抢购！",
                time: "  2024-9-23",
                wz: "https://mp.weixin.qq.com/s/lEPv2hxGm0ec7b-EDY98ZQ",
              },

            ],
            [
              {
                show: false,
                head: "金秋九月，《千年游戏盒子》签到积分翻倍，游戏礼包大换新，好礼送不停！",
                url: require("@/assets/image/wap/banner/jqjy.jpg"),
                info: "8月30日-9月3日，《千年游戏盒子》签到积分翻倍！8月30日起，千年盒子游戏礼包大换新，更有千年云挂机第N波福利来袭，快来领取兑换吧~",
                time: "  2024-8-27",
                wz: "https://mp.weixin.qq.com/s/DIQ9AfH0505vfgO0qBQtOg",
              },
              {
                show: false,
                head: "一篇文章带您了解千年游戏史！",
                url: require("@/assets/image/wap/banner/256-144.jpg"),
                info: "回味经典千年，致敬千年情怀！带您一键了解千年发展史，前往千年盒子参与活动更有云挂机好礼相赠！",
                time: "  2024-8-20",
                wz: "https://mp.weixin.qq.com/s/abzYupDefKAkLi9oBlRZtw",
              },


            ],
            [
              {
                show: false,
                head: "《千年游戏盒子》福利加码，千年定制扑克牌礼盒免费大放送！",
                url: require("@/assets/image/wap/banner/new8.jpg"),
                info: "8月20日-31日，《千年游戏盒子》960份千年定制版扑克牌免费兑换，更有66份惊喜随机放送！积分兑换，趣味互动，好礼送不停~",
                time: "  2024-8-20",
                wz: "https://mp.weixin.qq.com/s/N0V3L5tbZEo5MpCWE_DJ1w",
              },
              {
                show: false,
                head: "千年盒子PC(电脑）端下载教程",
                url: require("@/assets/image/wap/banner/new-1.jpg"),
                info: "千年盒子PC（电脑）端将于2024年8月13日开启限量内测，届时将在官网开放PC（电脑）端下载入口，诚邀广大千年侠客体验！",
                time: "  2024-8-10",
                wz: "https://mp.weixin.qq.com/s/SfqiCnSMZ8mvNaXA3P6_Mw",
              },


            ],

            [
              {
                show: false,
                head: "《千年游戏盒子》携手<千年定制版香薰>伴您过七夕！",
                url: require("@/assets/image/wap/banner/zxqx.jpg"),
                info: "爱在七夕，赠你甜蜜！为感谢侠客们对《千年游戏盒子》的支持和厚爱，我们精心准备了520套 “千年定制版车载香薰” ，数量有限，先到先得！",
                time: "  2024-8-3",
                wz: "https://mp.weixin.qq.com/s/836nCSF0PfGnW8YiQ4Jj2w",
              },
              {
                show: false,
                head: "《千年游戏盒子》庆祝祖国八一建军节特别活动",
                url: require("@/assets/image/wap/banner/zxjj.jpg"),
                info: "值此建军佳节，《千年游戏盒子》特推出签到积分翻倍、云挂机15日VIP卡免费送活动，致敬伟大中国人民解放军！",
                time: "  2024-8-3",
                wz: "https://mp.weixin.qq.com/s/iCvaeVBwQk5Y_6DahnL8fw",
              },


            ],
            [
              {
                show: false,
                head: "千年盒子官方福利：免费赠送3天云挂机VIP卡",
                url: require("@/assets/image/wap/banner/hz.png"),
                info: "千年游戏盒子PC（电脑）端即将开启对外内测！官方特准备了10000张云挂机3天VIP卡免费赠送给各位玩家,'助'您千年之路事半功倍！数量有限，先到先得，送完即止！",
                time: "  2024-7-16",
                wz: "https://mp.weixin.qq.com/s/aVSqvXaqN2sM_tgtNirLLQ",
              },
              {
                show: false,
                head: "千年游戏盒子PC(电脑）端与您相约8月！",
                url: require("@/assets/image/wap/banner/pchz.png"),
                info: "千年盒子PC（电脑）端将于2024年8月初开启限量内测，8月初我们将在官网开放PC（电脑）端下载入口，诚邀广大千年侠客体验！",
                time: "  2024-7-25",
                wz: "https://mp.weixin.qq.com/s/3f7J87OPvCtbykbR5izsNQ",
              },


            ],
            [
              {
                show: false,
                head: "官宣：《千年》网络游戏独家代理公告",
                url: require("@/assets/image/pc/bg/2.png"),
                info: "我们很荣幸的告知您（江苏三九互娱网络科技有限公司）已取得《千年》网络游戏在中国大陆地区独占性著作权相关权利，现诚邀各位合作伙伴加入我们，我们相信只有共同努力才能打造好千年游戏IP，为广大玩家提供更优质的游戏体验我们诚邀各位...",
                time: " 2023-10-23",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484840&idx=1&sn=197a0fc045c866b987b15428d7102acf&chksm=cf7d76abf80affbd735dce45cfb1cc8ae223e69160a13a54a3cb75c51e736cb9a014dd340fe8&token=1515630829&lang=zh_CN#rd",
              },
              {
                show: false,
                head: "致侠客们的一封信：10月13日19:00 特殊新服《中国大陆独代区》",
                url: require("@/assets/image/pc/bg/3.png"),
                info: "《中国大陆独代区》 对我们，对《千年》IP都意义非凡，在产品推广，产品运营，内容策划上我们都会尽最大努力做好，不失绿色服宗旨，给所有喜爱千年的侠客们一个真正的情怀之所10月13日19：00：新区《中国大陆独代区》扬帆起航。即将更新....",
                time: " 2023-10-05",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484817&idx=1&sn=531fd768d9500c4f89d75799b4c52f6e&chksm=cf7d7692f80aff842fbc3ee6d926b8c34ad79b9fc1db218092405cd58506c248c7758810fb90&token=1515630829&lang=zh_CN#rd",
              },

            ],
            [
              {
                show: false,
                head: "感恩回馈“千年定制版车载香薰”免费送咯",
                url: require("@/assets/image/pc/bg/4.jpg"),
                info: "千年盛世绿色版 自7月22日公测以来受到了广大千年侠客的喜爱和支持，非常感谢侠客们对我们的支持和厚爱，我们为此精心准备了600套 “千年定制版车载香薰” 回馈各位侠客,聊表心意… … ",
                time: "  2023-08-27",
                wz: "https://mp.weixin.qq.com/s?__biz=Mzg3OTk3ODA5Mg==&mid=2247484656&idx=1&sn=b43f0d1738b9f4808062cf3b47fd94eb&chksm=cf7d77f3f80afee51f25eebe4665aa22b73167f2832a8619dc8e7262405111105acf9c254a28&token=1515630829&lang=zh_CN#rd",
              },
              {
                show: false,
                head: "喜讯：千年游戏盒子上线咯",
                url: require("@/assets/image/pc/bg/4.png"),
                info: "回首那些年，经典的千年游戏曾经陪伴我们度过了无数个青春岁月。它不仅仅是游戏，更是我们童年的记忆，是我们与好友一起嬉戏的见证。然而，时间的流转让这些游戏逐渐随风而去，我们只能默默怀念，怀念那段美好而难忘的时光...",
                time: " 2023-11-20",
                wz: "https://mp.weixin.qq.com/s?__biz=MzkwNTUzNjI3OA==&mid=2247483889&idx=1&sn=a32733612696bb324ad7d9ce5ba7b26e&chksm=c0f77788f780fe9ed577648185bab9825f0dbfcd54d8a639c049a9f8dc9f85dd984ed4d1fbcd#rd",
              },
            ],
            [
              {
                show: false,
                head: "千年游戏盒子福利一览",
                url: require("@/assets/image/pc/bg/5.png"),
                info: "千年游戏盒子作为一站式游戏平台，集成了多个版本的千年游戏，让您无需在不同平台之间切换，只需在千年游戏盒子中选择游戏启动即可,千年游戏盒子注重玩家的账号和个人信息安全，采取了多种安全措施，防止恶意攻击和信息泄露....",
                time: "2023-11-13 ",
                wz: "https://mp.weixin.qq.com/s?__biz=MzkwNTUzNjI3OA==&mid=2247483857&idx=1&sn=f4a9a109e58430498cebfecd0d99c680&chksm=c0f777a8f780febeff7e4c2b1a2fa2a9126df15a57d69f6c1bb59af812ca4904db60e27b5ceb#rd",
              },
              {
                show: false,
                head: "中秋赏月“捉迷藏”、“猜灯谜”，千年定制扑克牌，云挂机15日VIP卡大放送！",
                url: require("@/assets/image/wap/banner/256-144o.jpg"),
                info: "中秋习俗大赏，9月16日-9月18日，每天12点，来《千年游戏盒子》，1积分抢千年定制扑克牌，云挂机15日VIP卡，数量有限，先到先得！",
                time: "  2024-9-16",
                wz: "https://mp.weixin.qq.com/s/90jEvtl5JpBA1hiKfxMyiA",
              },
            ]
          ],
        },
      ],
      gameSwiper: null, // 主体swiper
      gameSwiperList: [],
    };
  },
  created() { },
  mounted() {
    this.initSwiper();
  },
  methods: {
    changeNav(index) {
      console.log(index, "index");
      this.indexs = index;

      this.menuList.forEach((item) => {
        item.show = false;
      });
      this.menuList[index].show = true;
      // let slide_box = document.querySelector(".slide_box");
      // // slide_box.style.
      // slide_box.style.transitionDuration = "1s";
      // slide_box.style.transform = `translate3d(-${index}00%, 0px, 0px)`;

      this.gameSwiper.slideToLoop(index, 1000, false);
    },
    wapSingel(url) {
      if (!url) {
        return;
      }

      const dowa = document.createElement("a");
      dowa.href = url;
      dowa.target = "_blank";
      document.body.appendChild(dowa);
      dowa.click();
      document.body.removeChild(dowa);
    },
    // 初始化swiper
    initSwiper() {
      this.gameSwiper = new Swiper(".newCenter_swiper", {
        centeredSlides: true,
        // initialSlide: index,
        noSwiping: true,
        noSwipingClass: "stop-swiping",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      });
      this.gameSwiperList = [];
      this.menuList.forEach((item, index) => {
        let swiper = new Swiper(`.game_swiper_public${index}`, {
          centeredSlides: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletClass: "my-bullet",
            clickableClass: "my-pagination-clickable",
          },
          // autoplay: {
          //   delay: 3000,
          //   disableOnInteraction: false,
          // },
          loop: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            touchEnd: function (swiper, event) {
              console.log("出发蓝");
            },
          },
        });

        this.gameSwiperList.push(swiper);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.hand {
  cursor: pointer;
}

.max-width {
  max-width: 1920px;
  margin: 0 auto;
}

.original {
  height: 3.92rem;
  position: relative;
  z-index: 2;
  background-color: #fff;
}

.newCenter_box {
  height: 100%;
  background: url(../../assets/image/pc/bg/150.jpg) no-repeat 50%;

  // background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  .new_title {
    // width: 0.833333rem /* 160/192 */;
    height: 0.208333rem
      /* 40/192 */
    ;
    font-size: 0.208333rem
      /* 40/192 */
    ;
    color: #231c0e;
    font-weight: bold;
    margin-top: 0.390625rem
      /* 75/192 */
    ;
  }

  //内容
  .new_center_box {
    width: 6.3125rem
      /* 1212/192 */
    ;
    height: 2.79375rem
      /* 498/192 */
    ;
    background: #ffffff;
    border-radius: 0.052083rem
      /* 10/192 */
    ;
    opacity: 1;
    margin-top: 0.3125rem
      /* 60/192 */
    ;
    box-sizing: border-box;

    .new_menu {
      width: 6.041667rem
        /* 1160/192 */
      ;
      margin: 0 auto;
      border-bottom: 0.020833rem
        /* 4/192 */
        solid #f3f3f3;
      // background: palegoldenrod;
      text-align: center;
      line-height: 0.375rem;
      color: #000000;
      font-weight: bold;
      font-size: 24px;

      .new_menu_ul {
        display: flex;
        // align-items: center;
        // justify-content: space-evenly;
        position: relative;
        margin-top: 0.061458rem
          /* 31/192 */
        ;

        .menu_item {
          width: 1.510417rem
            /* 290/192 */
          ;
          height: 0.375rem
            /* 72/192 */
          ;
          text-align: center;
          // margin-left: 0.641667rem;
          cursor: pointer;
        }

        .active {
          // color: red;
          position: relative;
        }

        .active::after {
          content: "";
          position: absolute;
          left: 30%;
          bottom: -0.02rem
            /* 17/192 */
          ;
          width: 40%;
          height: 0.020833rem
            /* 4/192 */
          ;
          background: #e14b4b;
          border-radius: 0.026042rem
            /* 5/192 */
          ;
        }
      }
    }

    .new_content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 0.3125rem
        /* 60/192 */
      ;

      .item_content_box {
        padding: 0.161458rem
          /* 31/192 */
          0.182292rem
          /* 35/192 */
          0.203125rem
          /* 39/192 */
          0.135417rem
          /* 26/192 */
        ;
        box-sizing: border-box;

        .item_title {
          font-size: 0.09375rem
            /* 18/192 */
          ;
          color: #393333;
          font-weight: bold;
          cursor: pointer;
          margin-bottom: 0.083333rem
            /* 16/192 */
          ;
        }

        .item_title:hover {
          color: #cc5341;
        }

        .item_content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 0.677083rem
            /* 130/192 */
          ;
          margin-bottom: 0.166667rem
            /* 32/192 */
          ;
          width: 6.015625rem
            /* 1155/192 */
          ;

          .item_lef {
            width: 1.3125rem
              /* 252/192 */
            ;
            height: 0.677083rem
              /* 130/192 */
            ;
            border-radius: 0.052083rem
              /* 10/192 */
            ;

            margin-right: 0.104167rem
              /* 20/192 */
            ;
            cursor: pointer;
            overflow: hidden;

            img {
              // width: 1.3125rem /* 252/192 */;
              width: 100%;
              height: 100%;
              display: block;
              border-radius: 0.052083rem
                /* 10/192 */
              ;
              transition: transform 0.3s ease;
              // height: 0.677083rem;
            }

            img:hover {
              transform: scale(1.2);
              /* 鼠标悬停时放大图片 */
            }
          }

          .item_rig {
            display: flex;
            // align-items: center;
            // justify-content: center;
            flex-direction: column;
            width: 4.598958rem
              /* 883/192 */
            ;

            .item_info {
              width: 4.598958rem
                /* 883/192 */
              ;
              max-height: 0.286458rem
                /* 55/192 */
              ;
              display: flex;
              flex-wrap: wrap;
              font-size: 0.083333rem
                /* 16/192 */
              ;
              color: #393333;
              // margin-bottom: 0.140625rem /* 27/192 */;
              line-height: 0.15rem
                /* 44/192 */
              ;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              /* 设置显示的行数 */
              -webkit-box-orient: vertical;
            }

            .item_foot {
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              // width: 4.967708rem /* 877/192 */;
              height: 0.208333rem
                /* 40/192 */
              ;
              margin-top: 0.145833rem
                /* 28/192 */
              ;

              .item_time {
                color: #949494;
                font-size: 0.083333rem
                  /* 16/192 */
                ;
              }

              .item_btn {
                width: 0.645833rem
                  /* 124/192 */
                ;
                border-radius: 0.052083rem
                  /* 10/192 */
                ;
                height: 0.208333rem
                  /* 40/192 */
                ;
                background: #cc5341;
                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;

                .btn_img {
                  width: 0.046875rem
                    /* 9/192 */
                  ;
                  height: 0.083333rem
                    /* 16/192 */
                  ;
                  margin-left: 0.036458rem
                    /* 7/192 */
                  ;

                  img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    margin-left: 0.036458rem
                      /* 7/192 */
                    ;
                  }
                }

                .btn_tex {
                  font-size: 0.083333rem
                    /* 16/192 */
                  ;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }

    .empty_img {
      height: 2.197917rem
        /* 422/192 */
      ;
      width: 100%
        /* 1215/192 */
      ;
      // margin-left: .68rem /* 50/192 */;
      // margin-top: -1rem;
      // margin: 0 auto;
      // background: #f6f5f4;
      // margin-bottom: .104167rem /* 20/192 */;
      margin-top: 0.020833rem
        /* 4/192 */
      ;
      border-radius: 0.052083rem
        /* 10/192 */
      ;
      position: relative;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 32%;
        height: 99%;
      }
    }
  }
}

// swiper
.newCenter_swiper {
  flex: 1;
  width: 100%;
  box-sizing: border-box;

  .new_content_box {
    width: 100%;
    height: 100%;
  }
}

.empty_tex {
  text-align: center;
  position: absolute;
  top: 82%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #949494;
}
</style>
<style>
.newCenter_swiper .my-bullet+.my-bullet {
  margin-left: 0.0625rem;
}

.newCenter_swiper .my-bullet {
  display: inline-block;
  width: 0.046875rem
    /* 9/192 */
  ;
  height: 0.046875rem
    /* 9/192 */
  ;
  border-radius: 50%;
  background-color: #949494;
}

.newCenter_swiper .swiper-pagination-bullet-active {
  background-color: #cc5341 !important;
}

.newCenter_swiper .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0.2rem;
  left: 0%;
}
</style>
